import React from 'react'
import styled from 'styled-components'
import Anchor from './Anchor'
import 'typeface-montserrat'
import 'typeface-merriweather'

import { rhythm } from '../typography'

const LineHeightOverride = styled.div`
  lineHeight: ${rhythm(1.2)};
`

// TODO: Move content to markdown and revise so this a template


const About = () => {
  const isMobileView = typeof window !== 'undefined' && window.innerWidth < 1024

  return (
    <LineHeightOverride
      style={{
        lineHeight: rhythm(1.2)
      }}
    >
      <p>Hello! I'm a Software Engineer based in { isMobileView && <br /> } <strong>New York City.</strong>&nbsp;I
      currently build things at <Anchor href='https://www.unqork.com/' target='_blank'>Unqork</Anchor>, a&nbsp;
        <Anchor href='https://en.wikipedia.org/wiki/No-code_development_platform'>no code</Anchor>
        &nbsp;platform for enterprise software. My previous experience includes roles at&nbsp;
      <Anchor href='https://transfix.io/' target='_blank'>Transfix</Anchor> and&nbsp;
      <Anchor href='https://www.nytimes.com/' target='_blank'>The New York Times.</Anchor>
      </p>

      <p>
        I enjoy problem solving and the interdisciplinary nature of challenges
        involved in building and maintaining systems. While I predominately operate in a technical capacity,
        I enjoy product and the software business, particularly at startups. I'm motivated by the pursuit of great work.
      </p>
    </LineHeightOverride>
  )
}

export default About
