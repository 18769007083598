import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Card from "../components/Card"
import Header from "../components/Header"
import Footer from "../components/Footer"
import About from "../components/About"
import Anchor from "../components/Anchor"
import { Helmet } from "react-helmet"
import icon from '../favicon.ico'
import '../typography'


const BlogIndex = ({ data, location }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  return (
    <>
      { mounted
          ? (
            <Card>
              <Header />
              <About />
              <Footer />
              <Helmet
                title={siteTitle}
                link={[{rel: 'icon', href: icon}]}
              />
            </Card>
          )
          : null
      }
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
